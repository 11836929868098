import { Flex } from "@chakra-ui/react";
import Banner from "./Banner/Banner";
import RankAndTarget from "./RankAndTarget/RankAndTarget";
import CoreValues from "./CoreValues/CoreValues";
import Team from "./Team/Team";
import Support from "./Support/Support";
import Roadmap from "./Roadmap/Roadmap";

export default function AboutPage() {
  return (
    <Flex flexDirection={"column"} alignItems={"center"} gap={0}>
      <Banner />
      <RankAndTarget />
      <CoreValues />
      <Team />
      <Support />
      <Roadmap />
    </Flex>
  );
}
