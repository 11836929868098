import React from "react";
import { Flex, Image, Box } from "@chakra-ui/react";
import FutureApplicationImage from "../../../assets/images/home/future-application.png";

import styles from "./FutureApplication.module.scss";

const FutureApplication = () => {
  return (
    <Flex className={styles.container} paddingX={"40px"}>
      <Flex
        className={styles.futureApplicationWrapper}
        justifyContent="center"
        flexDir={"column"}
        alignItems="center"
        gap={["60px", "80px"]}
        padding={"40px"}
      >
        <Box fontSize={"24px"} fontWeight={"900"} lineHeight="20px">
          Future Application
        </Box>
        <Image
          src={FutureApplicationImage}
          alt="future application"
          width={["50%", "100%"]}
        />
      </Flex>
    </Flex>
  );
};

export default FutureApplication;
