import styles from "./CoreValues.module.scss";
import { Flex, Image, Text } from "@chakra-ui/react";

const cards = ["Innovation", "precision", "customer first"];
export default function CoreValues() {
  return (
    <Flex className={styles.container}>
      <Text className={styles.title}>Core Values</Text>
      <Flex className={styles.cardsContainer}>
        {cards.map((card, index) => (
          <Card
            key={index}
            content={card}
          />
        ))}
      </Flex>
    </Flex>
  );
}

function Card({ content }: any) {
  return (
    <Flex className={styles.card}>
      <Text className={styles.cardTitle}>{content}</Text>
    </Flex>
  );
}
