import { Text, Flex, Image } from "@chakra-ui/react";
import styles from "./Banner.module.scss";
import InnerSectionContainer from "../../MaxWidthContainer/InnerSectionContainer";
import bannerImage from "../../../assets/images/about/banner-bg.png";
import bannerMobileImage from "../../../assets/images/about/banner-bg-mobile.png";

export default function Banner() {
  return (
    <Flex className={styles.container}>
      <InnerSectionContainer>
        <Flex className={styles.innerContainer}>
          <Flex className={styles.leftPart}>
            <Text className={styles.title}>Company Profile of ERA AI</Text>
            <Text className={styles.desc}>
              Era AI is the world's first AI-driven comprehensive financial
              derivatives service platform, focusing on optimizing investment
              management and trading strategies by using advanced AI technology.
              Its unique value lies in realizing overall profit, real-time
              decision-making and automated trading through AI technology.
              Compared with traditional financial platforms and other blockchain
              financial platforms, it has significant advantages. Although Era
              AI is an emerging platform, its cooperation with top projects in
              the industry ensures its technological leadership and wide market
              acceptance.
            </Text>
          </Flex>

          <Image src={bannerImage} alt="Banner" className={styles.image} />
          <Image src={bannerMobileImage} alt="Banner" className={styles.imageMobile} />
        </Flex>
      </InnerSectionContainer>
    </Flex>
  );
}
