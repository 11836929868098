import styles from "./Team.module.scss";
import { Flex, Image, Text } from "@chakra-ui/react";
import profile1Image from "../../../assets/images/about/profile1.png";
import profile2Image from "../../../assets/images/about/profile2.png";

const cards = [
  {
    name: "John Doe",
    title: "CEO",
    avatar: profile1Image,
  },
  {
    name: "Jane Doe",
    title: "CTO",
    avatar: profile2Image,
  },
];
export default function Team() {
  return (
    <Flex className={styles.container}>
      <Text className={styles.title}>Our Team</Text>
      <Flex className={styles.cardsContainer}>
        {cards.map((card, index) => (
          <Card
            key={index}
            name={card.name}
            title={card.title}
            avatar={card.avatar}
          />
        ))}
      </Flex>
    </Flex>
  );
}

function Card(props: { name: string; title: string; avatar: string }) {
  const { name, title, avatar } = props;
  return (
    <Flex className={styles.card}>
      <Image src={avatar} alt="avatar" className={styles.avatar} />
      <Flex className={styles.cardContent}>
        <Text className={styles.name}>{name}</Text>
        <Text className={styles.userTitle}>{title}</Text>
      </Flex>
    </Flex>
  );
}
