import styles from "./Support.module.scss";
import { Flex, Image, Text } from "@chakra-ui/react";
import d3xImage from "../../../assets/images/home/d3x.png";

const cardGroup1 = [
  {
    title: "Technology integration",
    desc: "The deep integration of Era AI and D3X at the technical level realizes the seamless connection of leading AI and blockchain technologies.",
  },
  {
    title: "Data sharing",
    desc: "The collaboration between the two parties in data resources enables Era AI to conduct market analysis and investment decisions more accurately.",
  },
  {
    title: "Market synergy",
    desc: "Through cooperation, Era AI can quickly cut into the market network established by D3X and expand the user base.",
  },
];
export default function Support() {
  return (
    <Flex className={styles.container}>
      <Text className={styles.desc}>Support and strategic cooperation of</Text>
      <Image src={d3xImage} alt="D3X" className={styles.d3xImage} />

      <Flex className={styles.cardGroups}>
        <Flex className={styles.cardsGroupContainer}>
          <Text className={styles.cardGroupTitle}>strategic cooperation</Text>
          <Flex className={styles.cardsContainer}>
            {cardGroup1.map((card, index) => (
              <Card key={index} title={card.title} desc={card.desc} />
            ))}
          </Flex>
        </Flex>
        <Flex className={styles.cardsGroupContainer}>
          <Text className={styles.cardGroupTitle}>strategic cooperation</Text>
          <Flex className={styles.cardsContainer}>
            {cardGroup1.map((card, index) => (
              <Card key={index} title={card.title} desc={card.desc} />
            ))}
          </Flex>
        </Flex>
      </Flex>

      <Text className={styles.footerDesc}>
        Era AI and D3X will continue to deepen cooperation in the future and
        explore new cooperation opportunities in more fields. Through the
        technical and market synergy of both parties, jointly promote financial
        technology innovation in the Web 3.0 era.
      </Text>
    </Flex>
  );
}

function Card(props: { title: string; desc: string }) {
  const { title, desc } = props;
  return (
    <Flex className={styles.card}>
      <Text className={styles.cardTitle}>{title}</Text>
      <Text className={styles.cardDesc}>{desc}</Text>
    </Flex>
  );
}
