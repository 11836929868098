import { Text, Flex, Image } from "@chakra-ui/react";
import styles from "./Banner.module.scss";
import InnerSectionContainer from "../../MaxWidthContainer/InnerSectionContainer";
import bannerImage from "../../../assets/images/product/banner-bg.png";

export default function Banner() {
  return (
    <Flex className={styles.container}>
      <InnerSectionContainer>
        <Flex className={styles.innerContainer}>
          <Flex className={styles.leftPart}>
            <Text className={styles.title}>Product & Service</Text>
            <Text className={styles.desc}>
              There are problems such as information asymmetry, decision-making
              delays, and emotional interference in the traditional financial
              market. Era AI provides an intelligent decision-making system
              based on AI technology to solve the pain points of traditional
              finance and improve investment returns. At the same time, strong
              industry partnerships provide deep resource support for Era AI,
              ensuring that the platform has a leading advantage from the very
              beginning
            </Text>
          </Flex>

          <Image src={bannerImage} alt="Banner" className={styles.image} />
        </Flex>
      </InnerSectionContainer>
    </Flex>
  );
}
