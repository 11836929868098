import { Button, Flex, Image, Text } from "@chakra-ui/react";
import styles from "./About.module.scss";
import aboutImage from "../../../assets/images/home/react.png";
import { FaAngleRight } from "react-icons/fa";
export default function About() {
  return (
    <Flex className={styles.container}>
      <Text className={styles.title}>About Era.ai</Text>
      <Text className={styles.desc}>
        Era AI is the world's first AI-driven comprehensive financial
        derivatives service platform, focusing on leveraging AI technology to
        optimize investment management and trading strategies. Through AI
        technology, Era AI achieves overall profit, real-time decision-making,
        and automated trading, leading the traditional and other blockchain
        financial platforms.
      </Text>

      <Button className={styles.viewBtn}>View More <FaAngleRight /></Button>

      <Image src={aboutImage} alt="about" className={styles.image} />
    </Flex>
  );
}
