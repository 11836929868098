import { Box, Flex, Image, useDisclosure } from "@chakra-ui/react";
import styles from "./index.module.scss";
import logo from "../../assets/images/common/logo-text.png";
import MaxWidthContainer from "../MaxWidthContainer";
import { useNavigate } from "react-router";
import { IoMenu, IoClose } from "react-icons/io5";
import classNames from "classnames";

const HeaderLinks = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "About",
    url: "/about",
  },
  {
    name: "Product & Service",
    url: "/product",
  },
  {
    name: "News",
    url: "/#",
  },
];
export default function Header() {
  const navigate = useNavigate();
  const disclosure = useDisclosure();
  return (
    <Box className={styles.container}>
      <MaxWidthContainer>
        <Box className={styles.innerContainer}>
          <Image
            src={logo}
            alt="logo"
            className={styles.logo}
            onClick={() => navigate("/")}
          />

          <Box className={styles.links}>
            {HeaderLinks.map((link, index) => (
              <Box
                key={index}
                className={styles.link}
                onClick={() => navigate(link.url)}
              >
                {link.name}
              </Box>
            ))}
          </Box>

          <Box className={styles.mobileMenu}>
            <Box
              className={styles.mobileMenuIcon}
              onClick={disclosure.onToggle}
            >
              {disclosure.isOpen ? <IoClose /> : <IoMenu />}
            </Box>
          </Box>
        </Box>
      </MaxWidthContainer>
      <Flex
        className={classNames(styles.mobileMenu, styles.mobileMenuContainer, {
          [styles.show]: disclosure.isOpen,
        })}
      >
        {HeaderLinks.map((link, index) => (
          <Box
            key={index}
            className={classNames(styles.link, styles.mobileMenu)}
            onClick={() => {
              disclosure.onClose();
              navigate(link.url);
            }}
          >
            {link.name}
          </Box>
        ))}
      </Flex>
    </Box>
  );
}
