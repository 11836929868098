import styles from "./RankAndTarget.module.scss";
import rankImage from "../../../assets/images/about/rank.png";
import rankBgImage from "../../../assets/images/about/rank-bg.png";
import targetImage from "../../../assets/images/about/target.png";
import targetBgImage from "../../../assets/images/about/target-bg.png";
import { Flex, Image, Text } from "@chakra-ui/react";

export default function RankAndTarget() {
  return (
    <Flex className={styles.container}>
      <Image src={rankBgImage} alt="Rank" className={styles.rankBg} />
      <Image src={targetBgImage} alt="Target" className={styles.targetBg} />

      <Flex className={styles.rankContainer}>
        <Image src={rankImage} alt="Rank" className={styles.rankImage} />
        <Text className={styles.rankText}>Vision</Text>
        <Text className={styles.rankDesc}>
          Become the world's leading AI-driven comprehensive financial service
          platform and promote financial technology innovation in the Web 3.0
          era.
        </Text>
      </Flex>
      <Flex className={styles.targetContainer}>
        <Image src={targetImage} alt="Target" className={styles.targetImage} />
        <Text className={styles.rankText}>Mission</Text>
        <Text className={styles.rankDesc}>
          By integrating AI and blockchain technology, provide accurate
          financial decisions and support for investors and achieve global
          profitability.
        </Text>
      </Flex>
    </Flex>
  );
}
