import { Flex } from "@chakra-ui/react";
import Banner from "./Banner/Banner";
import CoreValues from "./CoreValues/CoreValues";
import Support from "./Support/Support";
import Markets from "./Markets/Markets";
import Ecosystem from "./Ecosystem/Ecosystem";
import Technologies from "./Technologies/Technologies";
import Introduction from "./Introduction/Introduction";

export default function Product() {
  return (
    <Flex flexDirection={"column"} alignItems={"center"} gap={0}>
      <Banner />
      <Introduction />
      <CoreValues />
      <Support />
      <Technologies />
      <Markets />
      <Ecosystem />
    </Flex>
  );
}
