import { Flex, Image, Text } from "@chakra-ui/react";
import styles from "./Partner.module.scss";
import d3xImage from "../../../assets/images/home/d3x.png";

export default function Partner() {
  return (
    <Flex className={styles.container}>
      <Text className={styles.title}>Strategic partner</Text>

      <Image src={d3xImage} alt="D3X" className={styles.image} />

      <Flex flexDirection="column" alignItems={"center"} gap={2}>
        <Text className={styles.desc}>
          Jointly promote financial technology innovation in the Web 3.0 era.
        </Text>
        <Text className={styles.desc}>
          Technology integration | Data sharing | Market synergy
        </Text>
      </Flex>
    </Flex>
  );
}
