import { Flex } from "@chakra-ui/react";
import Banner from "./Banner/Banner";
import Background from "./Background/Backgound";
import About from "./About/About";
import System from "./About/System";
import Service from "./Service/Service";
import Partner from "./Partner/Partner";
import News from "./News/News";
import FutureApplication from "./FutureApplication/FutureApplication";
import useScrollToTop from "../../hooks/useScrollToTop";
import { useEffect } from "react";

export default function Home() {
  return (
    <Flex flexDirection={"column"} alignItems={"center"} gap={4}>
      <Banner />
      <Background />
      <About />
      <System />
      <Service />
      <Partner />
      <FutureApplication />
      <News />
    </Flex>
  );
}
