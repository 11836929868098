import { Button, Flex, Text } from "@chakra-ui/react";
import styles from "./Backgound.module.scss";

const cards = [
  "The Internet has evolved from an information transmission platform into a new ecology of value interconnection and user dominance.",
  "AI technology represented by ChatGPT is reshaping the landscape of the financial investment field.",
  "The integration of Web 3.0 and AI brings huge market potential and development opportunities.",
];
export default function Background() {
  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      gap={10}
      className={styles.container}
    >
      <Text className={styles.title}>Background</Text>
      <Flex gap={4} className={styles.cardsContainer}>
        {cards.map((card, index) => (
          <Flex
            key={index}
            flexDirection={"column"}
            alignItems={"center"}
            className={styles.card}
          >
            <Text className={styles.cardText}>{card}</Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
