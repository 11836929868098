import styles from "./Support.module.scss";
import { Flex, Image, Text } from "@chakra-ui/react";
import d3xImage from "../../../assets/images/home/d3x.png";

// Era AI Technical Architecture
// AI Engine
// Data Processing Module
// The world's first AI-driven comprehensive financial derivatives service platform, focusing on using AI technology to optimize investment management and trading strategies.
// Through AI technology, it realizes overall profit, real-time decision-making and automated trading, leading traditional and other blockchain financial platforms.
// Trading System
// Powerful Alliance
// Although Era AI is an emerging platform, its cooperation with top projects in the industry ensures the leading nature of the platform's technology and wide market acceptance.
// Maintain close strategic partnerships with top teams in the industry to ensure the leading position of Era AI technology and wide market acceptance.
const cardGroup1 = [
  {
    title: "AI Engine",
    desc: "The world's first AI-driven comprehensive financial derivatives service platform, focusing on using AI technology to optimize investment management and trading strategies.",
  },
  {
    title: "Data Processing Module",
    desc: "Through AI technology, it realizes overall profit, real-time decision-making and automated trading, leading traditional and other blockchain financial platforms.",
  },
  {
    title: "Trading System",
    desc: "Although Era AI is an emerging platform, its cooperation with top projects in the industry ensures the leading nature of the platform's technology and wide market acceptance.",
  },
  {
    title: "Powerful Alliance",
    desc: "Maintain close strategic partnerships with top teams in the industry to ensure the leading position of Era AI technology and wide market acceptance.",
  },
];
export default function Support() {
  return (
    <Flex className={styles.container}>
      <Text className={styles.title}>Era AI Technical Architecture</Text>

      <Flex className={styles.cardGroups}>
        {cardGroup1.map((card, index) => (
          <Flex className={styles.cardsGroupContainer}>
            <Flex className={styles.cardsContainer}>
              <Card key={index} title={card.title} desc={card.desc} />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

function Card(props: { title: string; desc: string }) {
  const { title, desc } = props;
  return (
    <Flex className={styles.card}>
      <Text className={styles.cardTitle}>{title}</Text>
      <Text className={styles.cardDesc}>{desc}</Text>
    </Flex>
  );
}
