import { Button, Flex, Image, Text } from "@chakra-ui/react";
import styles from "./System.module.scss";
import aiImage from "../../../assets/images/home/ai.png";
import daImage from "../../../assets/images/home/da.png";
import marketImage from "../../../assets/images/home/market.png";
import { FaAngleRight } from "react-icons/fa";
const components = [
  {
    image: aiImage,
    title: "AI Intelligent Engine",
  },
  {
    image: marketImage,
    title: "Intelligent Trading System",
  },
  {
    image: daImage,
    title: "Data Analysis and Processing",
  },
];

export default function System() {
  return (
    <Flex className={styles.container}>
      <Text className={styles.title}>
        Era AI Intelligent Decision-making System
      </Text>
      <Text className={styles.desc}>Three core technologies</Text>

      <Flex className={styles.componentsContainer}>
        {components.map((component, index) => (
          <Flex key={index} className={styles.component}>
            <Image
              src={component.image}
              alt="component"
              className={styles.image}
            />
            <Text className={styles.componentTitle}>{component.title}</Text>
          </Flex>
        ))}
      </Flex>
      <Button className={styles.viewBtn}>View More <FaAngleRight /></Button>
    </Flex>
  );
}
