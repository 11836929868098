import styles from "./Markets.module.scss";
import { Flex, Image, Text } from "@chakra-ui/react";

// ERA AI involves in markets
// Web 3.0 trading market
// Mining machine market
// NFT trading market
// Physical AI products
// Depin market
const cards = [
  "Web 3.0 trading market",
  "Mining machine market",
  "NFT trading market",
  "Physical AI products",
  "Depin market",
];
export default function Markets() {
  return (
    <Flex className={styles.container}>
      <Text className={styles.title}>ERA AI involves in markets</Text>
      <Flex className={styles.cardsContainer}>
        {cards.map((card, index) => (
          <Card key={index} content={card} />
        ))}
      </Flex>
    </Flex>
  );
}

function Card({ content }: any) {
  return (
    <Flex className={styles.card}>
      <Text className={styles.cardTitle}>{content}</Text>
    </Flex>
  );
}
