import styles from "./Ecosystem.module.scss";
import { Flex, Image, Text } from "@chakra-ui/react";
// ERA AI WEB 3.0 ecosystem
// Trading system
// Measurement system
// Chain oil script system
// Data statistics system
// ERA AI public chain
const cards = [
  "Trading system",
  "Measurement system",
  "Chain oil script system",
  "Data statistics system",
  "ERA AI public chain",
];
export default function Ecosystem() {
  return (
    <Flex className={styles.container}>
      <Text className={styles.title}>ERA AI WEB 3.0 ecosystem</Text>
      <Flex className={styles.cardsContainer}>
        {cards.map((card, index) => (
          <Card key={index} content={card} />
        ))}
      </Flex>
    </Flex>
  );
}

function Card({ content }: any) {
  return (
    <Flex className={styles.card}>
      <Text className={styles.cardTitle}>{content}</Text>
    </Flex>
  );
}
