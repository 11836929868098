import { Text, Flex, Image } from "@chakra-ui/react";
import styles from "./Introduction.module.scss";
import InnerSectionContainer from "../../MaxWidthContainer/InnerSectionContainer";

export default function Introduction() {
  return (
    <Flex className={styles.container}>
      <InnerSectionContainer>
        <Flex className={styles.innerContainer}>
          <Flex className={styles.leftPart}>

          </Flex>
          <Flex className={styles.rightPart}>
            <Text className={styles.title}>
              Era AI Intelligent Decision-making System
            </Text>
            <Text className={styles.desc}>
              Era AI uses advanced artificial intelligence algorithms for
              efficient data analysis. It can capture market dynamics in real
              time and respond quickly, thereby adjusting strategies in a timely
              manner. By reducing the interference of human emotional factors,
              it enhances the objectivity and accuracy of decision-making and
              maximizes users' investment return rate. Powerful industry
              partnerships provide deep resource support for Era AI, ensuring
              that the platform has a significant competitive advantage from the
              very beginning of its launch.
            </Text>
          </Flex>
        </Flex>
      </InnerSectionContainer>
    </Flex>
  );
}
