import { Flex, Image, Text } from "@chakra-ui/react";
import styles from "./Service.module.scss";
import MaxWidthContainer from "../../MaxWidthContainer";
import serviceImage from "../../../assets/images/home/service.png";
import purpleStarImage from "../../../assets/images/home/purple-star.png";
import blueStarImage from "../../../assets/images/home/blue-star.png";
import classnames from "classnames";

export default function Service() {
  return (
    <Flex className={styles.container}>
      <MaxWidthContainer>
        <Flex className={styles.innerContainer}>
          <Flex className={styles.componentContainer}>
            <Image src={serviceImage} alt="service" className={styles.image} />
            <Flex className={styles.cardsWrapper}>
              <PurpleCard
                cardClassnam={styles.redCard}
                title="For individual investors"
                descs={[
                  "Intelligent and personalized investment advisory services.",
                  "Helping them improve the efficiency and accuracy of investment decisions.",
                ]}
              />
              <BlueCard
                cardClassnam={styles.blueCard}
                title="For institutional investors"
                descs={[
                  "Efficient and customized investment solutions.",
                  "Including comprehensive services such as data processing and analysis, investment strategy customization, and risk management.",
                ]}
              />
            </Flex>
          </Flex>
        </Flex>
      </MaxWidthContainer>
    </Flex>
  );
}

function PurpleCard({ cardClassname, title, descs }: any) {
  return (
    <Flex className={classnames(styles.card, styles.redCard)}>
      <Flex className={classnames(styles.cardTitle, styles.cardTitleRed)}>
        <Image src={purpleStarImage} alt="star" className={styles.star} />
        {title}
      </Flex>
      <Text className={styles.cardDesc}>Era AI provides:</Text>
      <ul className={styles.list}>
        {descs.map((desc: string, index: number) => (
          <li key={index}>{desc}</li>
        ))}
      </ul>
    </Flex>
  );
}

function BlueCard({ cardClassname, title, descs }: any) {
  return (
    <Flex className={classnames(styles.card, styles.blueCard)}>
      <Flex className={classnames(styles.cardTitle, styles.cardTitleBlue)}>
        <Image src={blueStarImage} alt="star" className={styles.star} />
        {title}
      </Flex>
      <Text className={styles.cardDesc}>Era AI provides:</Text>
      <ul className={styles.list}>
        {descs.map((desc: string, index: number) => (
          <li key={index}>{desc}</li>
        ))}
      </ul>
    </Flex>
  );
}
