import { Flex, Image, Text } from "@chakra-ui/react";
import styles from "./index.module.scss";
import footerBgImage from "../../assets/images/common/footer-bg.png";
import contactImage from "../../assets/images/common/contact.png";
import logoWithTextImage from "../../assets/images/common/logo-text.png";

const links = [
  {
    title: "LINKS",
    links: [
      { name: "Home", link: "/#" },
      { name: "About Us", link: "/#" },
      { name: "Product & Service", link: "/#" },
    ],
  },
  {
    title: "MEDIA",
    links: [
      { name: "Twitter", link: "https://x.com/Era_ai_" },
      { name: "Discord", link: "/#" },
      { name: "Telegram", link: "https://t.me/ERAa_i" },
      { name: "Medium", link: "/#" },
    ],
  },
  {
    title: "LEGAL",
    links: [
      { name: "Terms of Service", link: "/#" },
      { name: "Privacy Policy", link: "/#" },
    ],
  },
];

export default function Footer() {
  return (
    <Flex className={styles.container}>
      <Image src={footerBgImage} alt="Footer" className={styles.bgImage} />

      <Flex className={styles.leftPart}>
        <Image src={logoWithTextImage} alt="Logo" className={styles.logo} />
        <Flex className={styles.contactWrapper}>
          <Image
            src={contactImage}
            alt="Contact"
            className={styles.contactImage}
          />
          <Text className={styles.contactText}>CONTACT@ERA.AI</Text>
        </Flex>
      </Flex>

      <Flex className={styles.rightPart}>
        {links.map((link, index) => (
          <LinkGroup key={index} title={link.title} links={link.links} />
        ))}
      </Flex>
    </Flex>
  );
}

function LinkGroup({
  title,
  links,
}: {
  title: string;
  links: { name: string; link: string }[];
}) {
  return (
    <Flex flexDirection={"column"} gap={2}>
      <Text className={styles.linkTitle}>{title}</Text>
      {links.map((link, index) => (
        <a
          key={index}
          className={styles.link}
          href={link.link === "/#" ? undefined : link.link}
          target={link.link === "/#" ? undefined : "_blank"}
          rel={link.link === "/#" ? undefined : "noopener noreferrer"}
          style={{
            cursor: link.link === "/#" ? "default" : "pointer",
            pointerEvents: link.link === "/#" ? "none" : "auto",
          }}
        >
          {link.name}
        </a>
      ))}
    </Flex>
  );
}
