import { title } from "process";
import styles from "./CoreValues.module.scss";
import { Flex, Image, Text } from "@chakra-ui/react";

const cards = [
  {
    title: "positioning",
    content:
      "The world's first AI-driven comprehensive financial derivatives service platform, focusing on using AI technology to optimize investment management and trading strategies.",
  },
  {
    title: "unique value",
    content:
      "Through AI technology, it realizes overall profit, real-time decision-making and automated trading, leading traditional and other blockchain financial platforms.",
  },
  {
    title: "backing force",
    content:
      "Although Era AI is an emerging platform, its cooperation with top projects in the industry ensures the leading nature of the platform's technology and wide market acceptance.",
  },
];
export default function CoreValues() {
  return (
    <Flex className={styles.container}>
      <Text className={styles.title}>Era AI positioning</Text>
      <Flex className={styles.cardsContainer}>
        {cards.map((card, index) => (
          <Card key={index} title={card.title} content={card.content} />
        ))}
      </Flex>
    </Flex>
  );
}

function Card({ content, title }: any) {
  return (
    <Flex className={styles.card}>
      <Text className={styles.cardTitle}>{title}</Text>
      <Text className={styles.cardContent}>{content}</Text>
    </Flex>
  );
}
