import { Button, Flex, Text } from "@chakra-ui/react";
import styles from "./Banner.module.scss";
import { FaAngleRight } from "react-icons/fa";

export default function Banner() {
  const handleButtonClick = () => {
    const url = "https://app.eraai.pro";
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <Flex
      flexDirection={"column"}
      alignItems={"center"}
      gap={4}
      className={styles.container}
    >
      <Text className={styles.title}>ERA AI</Text>
      <Text className={styles.desc1}>
        Leading financial technology innovation.
      </Text>
      <Text className={styles.desc2}>
        A new era financial platform integrating AI artificial intelligence and
        Web 3.0.
      </Text>
      <Button className={styles.btn} onClick={handleButtonClick}>
        Get Started <FaAngleRight />
      </Button>
    </Flex>
  );
}
