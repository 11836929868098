import styles from "./News.module.scss";
import { Flex, Image, Text } from "@chakra-ui/react";

const newsList = [
  {
    title: "The U.S. Bitcoin spot ETF had a net outflow of $887 million this week,...",
    desc: "BlockBeats news, on March 23, according to data from farside investors, the U.S. Bitcoin spot ETF had a net outflow of ...",
    link: "/#",
  },
  {
    title: "The U.S. Bitcoin spot ETF had a net outflow of $887 million this week,...",
    desc: "BlockBeats news, on March 23, according to data from farside investors, the U.S. Bitcoin spot ETF had a net outflow of ...",
    link: "/#",
  },
];

export default function News() {
  return (
    <Flex className={styles.container}>
      <Text className={styles.title}>Latest News</Text>

      <Flex className={styles.newsWrapper}>
        {newsList.map((news, index) => (
          <Flex
            key={index}
            className={styles.news}
          >
            <Text className={styles.newsTitle}>{news.title}</Text>
            <Text className={styles.desc}>{news.desc}</Text>
            <a className={styles.link} href={news.link} target="_blank" rel="noreferrer">
              Read more
            </a>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
