import styles from "./Roadmap.module.scss";
import { Flex, Image, Text } from "@chakra-ui/react";
import roadmapImage from "../../../assets/images/about/roadmap.png";
import roadmapMobileImage from "../../../assets/images/about/roadmap-mobile.png";

export default function Roadmap() {
  return (
    <Flex className={styles.container}>
      <Text className={styles.title}>Roadmap</Text>

      <Image src={roadmapImage} alt="roadmap" className={styles.roadmap} />
      <Image src={roadmapMobileImage} alt="roadmap" className={styles.roadmapMobileImage} />
    </Flex>
  );
}
