import { ChakraProvider, Flex, Image } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import styles from "./App.module.scss";

import bg1 from "./assets/images/common/bg-line1.png";
import threeLens from "./assets/images/common/three-lens.png";
import polygon1Image from "./assets/images/common/polygon1.png";
import polygon2Image from "./assets/images/common/polygon2.png";
import polygon3Image from "./assets/images/common/polygon3.png";

import useScrollToTop from "./hooks/useScrollToTop";
import { useEffect } from "react";

function App() {
  const scrollToTopx = useScrollToTop();
  const { search, pathname } = useLocation();
  console.log("pathname", pathname);

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  return (
    <>
      <ChakraProvider>
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          className={styles.container}
          overflow={"hidden"}
        >
          <Flex className={styles.bgLayerWrapper}>
            <Flex className={styles.bgLayer}>
              <Image src={bg1} alt="bg-layer" className={styles.bg1} />
              {pathname === "/" && (
                <>
                  <Image
                    src={threeLens}
                    alt="bg-layer"
                    className={styles.threeLens}
                  />
                  <Image
                    src={polygon1Image}
                    alt="bg-layer"
                    className={styles.polygon1}
                  />
                  <Image
                    src={polygon2Image}
                    alt="bg-layer"
                    className={styles.polygon2}
                  />
                  <Image
                    src={polygon3Image}
                    alt="bg-layer"
                    className={styles.polygon3}
                  />
                </>
              )}
            </Flex>
          </Flex>

          <Header />
          <Outlet />
          <Footer />
        </Flex>
      </ChakraProvider>
    </>
  );
}

export default App;
