import styles from "./Technologies.module.scss";
import { Flex, Image, Text } from "@chakra-ui/react";
import d3xImage from "../../../assets/images/home/d3x.png";
// AI Intelligent Engine

const cardGroup1 = [
  {
    title: "Large model architecture",
    desc: "Era AI integrates a financial large model with trillions of parameters and has real-time market analysis and intelligent decision-making capabilities.",
  },
  {
    title: "Core functions",
    desc: "Efficient data mining, accurate investment advice, and automated trading execution.",
  },
  {
    title: "Technical support",
    desc: "With the support of partners, Era AI's AI intelligent engine has reached the industry-leading level.",
  },
];

// Intelligent Trading System
// Ultra-high-frequency trading
// Use the AI engine to capture market opportunities and execute trading strategies within microseconds.
// Intelligent trading robots
// Including intelligent arbitrage, strategic trading, and copy trading robots, providing diversified trading solutions.
// Innovation ability
// Through technical collaboration with strategic partners, Era AI has achieved breakthrough progress in ultra-high-frequency trading and intelligent trading.

const cardGroup2 = [
  {
    title: "Ultra-high-frequency trading",
    desc: "Use the AI engine to capture market opportunities and execute trading strategies within microseconds.",
  },
  {
    title: "Intelligent trading robots",
    desc: "Including intelligent arbitrage, strategic trading, and copy trading robots, providing diversified trading solutions.",
  },
  {
    title: "Innovation ability",
    desc: "Through technical collaboration with strategic partners, Era AI has achieved breakthrough progress in ultra-high-frequency trading and intelligent trading.",
  },
];
// 
// Data pipeline
// From data collection to analysis, Era AI processes on-chain and off-chain data by using NLP models.
// Sentiment analysis
// Conduct real-time analysis of market sentiment through the combination of social media and market data.
// Data support
// Powerful partners provide rich data support for Era AI, enabling the platform to accurately analyze market sentiment.
const cardGroup3 = [
  {
    title: "Data pipeline",
    desc: "From data collection to analysis, Era AI processes on-chain and off-chain data by using NLP models.",
  },
  {
    title: "Sentiment analysis",
    desc: "Conduct real-time analysis of market sentiment through the combination of social media and market data.",
  },
  {
    title: "Data support",
    desc: "Powerful partners provide rich data support for Era AI, enabling the platform to accurately analyze market sentiment.",
  },
];

export default function Technologies() {
  return (
    <Flex className={styles.container}>
      <Text className={styles.title}>Three core technologies</Text>

      <Flex className={styles.cardGroups}>
        <Flex className={styles.cardsGroupContainer}>
          <Text className={styles.cardGroupTitle}>AI Intelligent Engine</Text>
          <Flex className={styles.cardsContainer}>
            {cardGroup1.map((card, index) => (
              <Card key={index} title={card.title} desc={card.desc} />
            ))}
          </Flex>
        </Flex>
        <Flex className={styles.cardsGroupContainer}>
          <Text className={styles.cardGroupTitle}>Intelligent Trading System</Text>
          <Flex className={styles.cardsContainer}>
            {cardGroup2.map((card, index) => (
              <Card key={index} title={card.title} desc={card.desc} />
            ))}
          </Flex>
        </Flex>
        <Flex className={styles.cardsGroupContainer}>
          <Text className={styles.cardGroupTitle}>Data Analysis and Processing</Text>
          <Flex className={styles.cardsContainer}>
            {cardGroup3.map((card, index) => (
              <Card key={index} title={card.title} desc={card.desc} />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

function Card(props: { title: string; desc: string }) {
  const { title, desc } = props;
  return (
    <Flex className={styles.card}>
      <Text className={styles.cardTitle}>{title}</Text>
      <Text className={styles.cardDesc}>{desc}</Text>
    </Flex>
  );
}
